import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../domain/services/configService';
import { TextInput } from '../atoms/TextInput';
import { IconWrapper } from '../molecules/IconWrapper';
import { Actionable } from '../atoms/Actionable';
import { IconClose } from '../../Icon/IconClose';

export const SearchText = ({
  initialText,
  placeholder,
  onChange,
  closeCb,
  updateHistory,
  instant,
  type
}) => {
  const [initialTextValue, setInitialTextValue] = useState(initialText);
  const [text, setText] = useState(initialText);
  // Ensure that searching from ExpandedSearch triggers a new search
  useEffect(() => {
    if (initialText !== initialTextValue) {
      setText(initialText);
      setInitialTextValue(initialText);
    }
  });

  const commitChange = value => {
    if (updateHistory) {
      const urlSearchParams = new URLSearchParams(document.location.search);
      urlSearchParams.set('q', value);
      const url = `${window.location.pathname}?${urlSearchParams.toString()}`;
      window.history.replaceState({}, null, url);
    }
    // Call parent
    onChange(value);
  };

  const handleChange = event => setText(event.target.value);

  const handleKeyUp = event => {
    if (
      instant ||
      event.key === 'Enter' ||
      event.which === 13 ||
      event.keyCode === 13
    ) {
      commitChange(event.target.value);
    }
  };

  const handleBlur = event => {
    commitChange(event.target.value);
  };

  return (
    <IconWrapper
      icon="search"
      size={type === 'large' ? 'large' : 'small'}
      border={type === 'small'}
      color="black"
      borderColor={type === 'small' ? '#ccc' : '#000'}
      underlined={type === 'large'}
    >
      <TextInput
        name="q"
        value={text}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        placeholder={placeholder}
        autocomplete={false}
        size={type === 'large' ? 'large' : 'small'}
        uppercase={type === 'large'}
      />
      {!!text && (
        <Actionable
          onClick={() => {
            setText('');
            closeCb();
          }}
          display="inline"
        >
          <IconClose size={24} display="block" color="black" />
        </Actionable>
      )}
    </IconWrapper>
  );
};

SearchText.defaultProps = {
  initialText: '',
  placeholder: t('Search'),
  updateHistory: true,
  instant: false,
  type: 'large'
};

SearchText.propTypes = {
  initialText: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  closeCb: PropTypes.func.isRequired,
  updateHistory: PropTypes.bool,
  instant: PropTypes.bool,
  type: PropTypes.oneOf(['small', 'large'])
};
