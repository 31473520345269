import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';

/**
 * Component for text inputs inside a form
 *
 * There is a "onChange" prop that transforms this component into a
 * controlled component (@see https://reactjs.org/docs/forms.html#controlled-components)
 * so this field value is re-rendered from outside it.
 * If "onChange" prop is not present, this component stays "uncontrolled"
 * (@see https://reactjs.org/docs/uncontrolled-components.html) so it's not
 * re-rendered on every text change.
 *
 * @param string onChange - Toggles the component controlled or uncontrolled
 *
 */
export const TextInput = ({
  name,
  value,
  onChange,
  onKeyUp,
  onBlur,
  autoFocus,
  size,
  placeholder,
  autocomplete,
  uppercase,
  inverted
}) => {
  const baseColor = inverted ? '#fff' : '#000';

  const fontSizeMediaQueries =
    size === 'large' &&
    css`
      font-size: 26px;
      ${theme.mq.tablet} {
        font-size: 45px;
      }
      ${theme.mq.small_desktop} {
        font-size: 60px;
      }
    `;

  const styles = {
    input: css`
      font-size: ${size === 'large' ? '26px' : 'clamp(.875em,2vw + 1rem,1em)'};
      ${fontSizeMediaQueries};
      background: transparent;
      ${theme.font.family(size === 'large' ? 'boldWeb' : 'regularWeb')};
      letter-spacing: ${size === 'large' ? '3px' : null};
      line-height: 1.5;
      font-style: normal;
      color: ${baseColor};
      border: none;
      outline: 0;
      width: 100%;
      text-transform: ${uppercase ? ' uppercase;' : 'none'};
    `
  };

  const valueProps = onChange
    ? { value, onChange }
    : { value: undefined, defaultValue: value };

  return (
    <input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      name={name}
      css={styles.input}
      type="text"
      placeholder={placeholder}
      autoComplete={autocomplete ? 'on' : 'off'}
      {...valueProps}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
    />
  );
};

TextInput.defaultProps = {
  name: undefined,
  value: undefined,
  onChange: undefined,
  onKeyUp: undefined,
  onBlur: undefined,
  autoFocus: false,
  size: 'small',
  placeholder: undefined,
  autocomplete: true,
  uppercase: false,
  underlined: false,
  inverted: false
};

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  placeholder: PropTypes.string,
  autocomplete: PropTypes.bool,
  uppercase: PropTypes.bool,
  underlined: PropTypes.bool,
  inverted: PropTypes.bool
};
